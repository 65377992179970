<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md4>
        <v-card outlined>
          <v-card-text>
            {{ $t("general.openWebPage", { linkName: $route.params.link }) }}
          </v-card-text>
          <v-card-text>
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-btn text block color="primary" @click="abortRedirect">{{
              $t("modal.cancel")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import MobileDetect from "mobile-detect";
const { detect } = require("detect-browser");
const browser = detect();

export default {
  data() {
    return {
      redirectTimeout: undefined,
    };
  },
  mounted() {
    const isIos = new MobileDetect(window.navigator.userAgent).os() === "iOS";
    const isFirefox = browser.name === "firefox";
    this.redirectTimeout = setTimeout(() => {
      // open in blank not working on IOS
      if ((this.$config.OPEN_LINK_NEW_TAB || isFirefox) && !isIos) {
        window.open(this.$route.params.link, "_system");
        this.$router.back();
      } else {
        location.replace(this.$route.params.link);
      }
    }, 1000);
  },
  methods: {
    abortRedirect() {
      clearTimeout(this.redirectTimeout);
      this.$router.back();
    },
  },
};
</script>
